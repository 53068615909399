import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";

import ErrorBoundary from "./components/ErrorBoundary";

import RoutesComponent from "./RoutesComponent";

function App() {
  // const { t } = useTranslation();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      setIsAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    setIsAuthenticated(false);

    navigate("/login");
  }

  return (
    !isAuthenticating && (
      <Container component="main" disableGutters maxWidth="md" sx={{ mt: "15px" }} className="App">
        <div className="App container">
          <AppBar position="static" sx={{ mb: 3, backgroundColor: theme.palette.grey[100] }}>
            <Toolbar>
              <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                <Link href="/" sx={{ fontWeight: "bold" }} underline="none">
                  <Typography variant="h6" underline="none">
                    Scratch
                  </Typography>
                </Link>
                <Box>
                  {isAuthenticated ? (
                    <>
                      <Button href="/settings">Settings</Button>

                      <Button onClick={handleLogout}>Logout</Button>
                    </>
                  ) : (
                    <>
                      <Button href="/login" variant="text">
                        Login
                      </Button>
                      <Button href="/signup" variant="text">
                        Signup
                      </Button>
                    </>
                  )}
                </Box>
              </Stack>
            </Toolbar>
          </AppBar>

          <ErrorBoundary>
            <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
              <RoutesComponent />
            </AppContext.Provider>
          </ErrorBoundary>
        </div>
      </Container>
    )
  );
}

export default App;
