import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { withTranslation } from "react-i18next";

import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import LoadingView from "./components/LoadingView";

// See https://reactjs.org/docs/code-splitting.html for code splitting
// and https://react.i18next.com/latest/using-with-hooks#using-the-withtranslation-hoc for i18n
const Home = lazy(() => import("./views/Home"));
const THome = withTranslation()(Home);

const Login = lazy(() => import("./views/Login"));
const TLogin = withTranslation()(Login);

const Signup = lazy(() => import("./views/Signup"));
const TSignup = withTranslation()(Signup);

const ResetPassword = lazy(() => import("./views/ResetPassword"));
const TResetPassword = withTranslation()(ResetPassword);

const Notes = lazy(() => import("./views/Notes"));
const TNotes = withTranslation()(Notes);

const NewNote = lazy(() => import("./views/NewNote"));
const TNewNote = withTranslation()(NewNote);

const Settings = lazy(() => import("./views/Settings"));
const TSettings = withTranslation()(Settings);

const ChangePassword = lazy(() => import("./views/ChangePassword"));
const TChangePassword = withTranslation()(ChangePassword);

const NotFound = lazy(() => import("./views/NotFound"));
const TNotFound = withTranslation()(NotFound);

export default function RoutesComponent({ childProps }) {
  return (
    <Suspense fallback={<LoadingView />}>
      <Routes>
        <Route
          path="/"
          element={
            <AppliedRoute>
              <THome />
            </AppliedRoute>
          }
        />
        <Route
          path="login"
          element={
            <UnauthenticatedRoute>
              <TLogin />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="signup"
          element={
            <UnauthenticatedRoute>
              <TSignup />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="login/reset"
          element={
            <UnauthenticatedRoute>
              <TResetPassword />
            </UnauthenticatedRoute>
          }
        />
        <Route
          path="notes/new"
          element={
            <AuthenticatedRoute>
              <TNewNote />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="notes/:id"
          element={
            <AuthenticatedRoute>
              <TNotes />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <AuthenticatedRoute>
              <TSettings />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/settings/password"
          element={
            <AuthenticatedRoute>
              <TChangePassword />
            </AuthenticatedRoute>
          }
        />
        {/* Finally, catch all unmatched routes */}
        <Route path="*" element={<TNotFound />} />
      </Routes>
    </Suspense>
  );
}
