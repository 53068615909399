// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-notes-186070825667-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://dkkpyahbkg.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_wFSYG3qzi",
    APP_CLIENT_ID: "1r1pmfcs1lbsl5ks9dpo6fb55o",
    IDENTITY_POOL_ID: "eu-central-1:95cc9844-5450-4b65-949e-3ce3f06faef6",
  },
  locales: {
    URL: "https://locales.notes.prod.hlgr360.net",
  },
  registration: {
    URL: "https://registration.notes.prod.hlgr360.net/v1",
    NEXT_PAGE: "https://app.notes.prod.hlgr360.net",
    TO_REFERRER: "https://app.notes.prod.hlgr360.net",
  },
  product: {
    PRODUCT_KEY: "notes",
  },
};

export default config;
