/**
 * @fileOverview Configuration
 *
 * @namespace configuration
 *
 */
const config = {
  /**
   * @memberof configuration
   * @property {object}webapp - Webapp defaults.
   */
  webapp: {
    MAX_ATTACHMENT_SIZE: 5000000,

    APP_API_ID: "app-be",
    NOTES_API_ENDPOINT: "/notes",
    ACCOUNT_API_ENDPOINT: "/account",
    CLIENT_API_ENDPOINT: "/clients",
    USER_API_ENDPOINT: "/users",
    EVENT_API_ENDPOINT: "/events",

    SIGNUP_API_ID: "registration",
    FORMS_API_ENDPOINT: "/forms",
    EMAILS_API_ENDPOINT: "/emails",
    COUPON_API_ENDPOINT: "/coupons",
  },

  /**
   * @memberof configuration
   * @property {object}urn - Urn types
   */
  urn: {
    APP: "webapp",
    ATTACHMENT: "attachment",
    LOGO: "logo",
  },

  /**
   * @memberof configuration
   * @property {object}app_event - app events
   */
  app_event: {
    TYPES: {
      LOGIN: "login",
    },
  },

  /**
   * @memberof configuration
   * @property {object}shared_links - Shared links.
   */
  shared_links: {
    IMPRINT: "https://companylinks.com/impressum/",
    TERMS_URL: "https://companylinks.com/allgemeine-geschaeftsbedingungen/",
    PRIVACY_URL: "https://companylinks.com/datenschutzerklaerung/",
  },

  /**
   * @memberof configuration
   * @property {object}product - product settings.
   */
  defaults: {
    PRODUCT_ID: "notes",
    PRICING_ID: "notes_01",
  },
};

export default config;
