import React from "react";

import "./LoadingView.css";

// See https://stackoverflow.com/a/61598220 for fade-in and fake-delay
// and https://bootsnipp.com/snippets/V73xN for loader design
export default function LoadingView() {
  const containerRef = React.useRef();
  return (
    <div ref={containerRef} className="loading-fadein" id="loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}
