import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "./i18n";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import config from "./config";

// See https://serverless-stack.com/chapters/configure-aws-amplify.html
import { Amplify } from "aws-amplify";
import TAwsConfig from "./awsConfig";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: TAwsConfig.cognito.REGION,
    userPoolId: TAwsConfig.cognito.USER_POOL_ID,
    identityPoolId: TAwsConfig.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: TAwsConfig.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: TAwsConfig.s3.REGION,
    bucket: TAwsConfig.s3.BUCKET,
    identityPoolId: TAwsConfig.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: config.webapp.APP_API_ID,
        endpoint: TAwsConfig.apiGateway.URL,
        region: TAwsConfig.apiGateway.REGION,
      },
      {
        name: config.webapp.SIGNUP_API_ID,
        endpoint: TAwsConfig.registration.URL,
        region: TAwsConfig.apiGateway.REGION,
      },
    ],
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
