// https://serverless-stack.com/chapters/give-feedback-while-logging-in.html
export function onError(error) {
  if (error === undefined) return;
  let message = error.toString();
  // Auth errors
  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }
  alert(message);
}
